import { z } from 'zod';
import { signingAgentSchema } from './signing-agent';

export const getSigningAgentsResponseSchema = z.object({
  data: signingAgentSchema.array(),
});

export type GetSigningAgentsResponse = z.infer<
  typeof getSigningAgentsResponseSchema
>;
