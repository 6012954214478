/**
 * String utility for checking if a string is effectively useless
 * @param s the string to check
 * @returns true if it is null/undefined/empty
 */
export function isNullOrEmpty(
  s: string | null | undefined
): s is null | undefined {
  return s === null || s === undefined || s.trim() === '';
}

/**
 * String utility for setting values to null or undefined if the string is useless
 * @param s the string to check
 * @returns
 */
export function nullIfNothing(
  s: string | null | undefined
): string | null | undefined {
  if (s === null) return null;
  if (s === undefined) return undefined;

  return s.trim() === '' ? null : s;
}

/**
 * String utility for setting values to null if the string is useless
 * @param s the string to check
 * @returns
 */
export function nullIfEmpty(s: string | null): string | null {
  if (s === null) return null;
  return s.trim() === '' ? null : s;
}

export function createRandomEin() {
  return Math.floor(Math.random() * 1000000000).toString();
}

/**
 * Compares two strings after normalizing them by converting to lowercase and removing non-alphanumeric characters.
 * @param a - The first string to compare. Can be a string, null, or undefined.
 * @param b - The second string to compare. Can be a string, null, or undefined.
 * @returns `true` if the normalized strings are equal, `false` otherwise. Returns `false` if either string is null or undefined.
 */
export function normalizedStringCompare(
  a: string | null | undefined,
  b: string | null | undefined
): boolean {
  if (!a || !b) {
    return false;
  }

  return (
    a.toLowerCase().replace(/[^a-z0-9]/g, '') ===
    b.toLowerCase().replace(/[^a-z0-9]/g, '')
  );
}

export const makeFullName = (...names: (string | undefined | null)[]) =>
  names.filter(Boolean).join(' ');

export const splitFullName = (fullName: string) => {
  const names = fullName.trim().split(' ');

  if (!fullName || !names.length) {
    return {
      firstName: null,
      middleName: null,
      lastName: null,
    };
  }

  if (names.length === 1) {
    return {
      firstName: names[0],
      middleName: null,
      lastName: null,
    };
  }

  if (names.length === 2) {
    return {
      firstName: names[0],
      middleName: null,
      lastName: names[1],
    };
  }

  const lastIndex = names.length - 1;

  const firstName = names[0];
  const lastName = names[lastIndex];
  const middleName = names
    .filter((_, i) => i !== 0 && i !== lastIndex)
    .join(' ');

  return {
    firstName,
    middleName,
    lastName,
  };
};
