import { DbCalendarDateNullable, DbTimestampGenerated } from '@harvestiq/utils';
import { zCalendarDate, zTimestamp } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import {
  ColumnType,
  GeneratedAlways,
  Insertable,
  Selectable,
  Updateable,
} from 'kysely';
import { z } from 'zod';

export type AgentsTable = {
  id: GeneratedAlways<string>;
  aipCode: ColumnType<string | null>;
  reinsuranceYear: ColumnType<number | null>;
  aipInsuranceAgentKey: ColumnType<string | null>;
  agentSsnEncrypted: ColumnType<string | null>;
  userId: ColumnType<string | null>;
  lastName: ColumnType<string | null>;
  firstName: ColumnType<string | null>;
  middleName: ColumnType<string | null>;
  suffix: ColumnType<string | null>;
  title: ColumnType<string | null>;
  email: ColumnType<string | null>;
  phone: ColumnType<string | null>;
  inactiveDate: DbCalendarDateNullable;
  additionalLanguageCodeList: ColumnType<string | null>;
  listedFlag: ColumnType<string | null>;
  nonDisclosureSignatureDate: DbCalendarDateNullable;
  annualReviewDate: DbCalendarDateNullable;
  coiQuestionnaireCompletionDate: DbCalendarDateNullable;
  coiQuestionnaireAmendedDate: DbCalendarDateNullable;
  coiResponseCarryoverFlag: ColumnType<string | null>;
  coiQuestion1ResponseFlag: ColumnType<string | null>;
  coiQuestion2ResponseFlag: ColumnType<string | null>;
  coiQuestion3ResponseFlag: ColumnType<string | null>;
  coiQuestion4ResponseFlag: ColumnType<string | null>;
  coiQuestion5ResponseFlag: ColumnType<string | null>;
  coiQuestion6ResponseFlag: ColumnType<string | null>;
  coiQuestion7ResponseFlag: ColumnType<string | null>;
  coiQuestion8ResponseFlag: ColumnType<string | null>;
  coiQuestion9ResponseFlag: ColumnType<string | null>;
  coiQuestion10ResponseFlag: ColumnType<string | null>;
  coiQuestion11ResponseFlag: ColumnType<string | null>;
  raceQuestionTypeCode: ColumnType<string | null>;
  ethnicityQuestionTypeCode: ColumnType<string | null>;
  genderQuestionTypeCode: ColumnType<string | null>;
  reinsuranceTypeCode: ColumnType<string | null>;
  createdBy: ColumnType<string | null>;
  updatedBy: ColumnType<string | null>;
  importSourceId: ColumnType<string | null>;
  createdAt: DbTimestampGenerated | null;
  updatedAt: DbTimestampGenerated | null;
};

export type DbAgent = Selectable<AgentsTable>;
export type DbAgentInsert = Insertable<AgentsTable>;
export type DbAgentUpdate = Updateable<AgentsTable>;

// zod schema
export const dbAgentSchema = z.object({
  id: z.string().uuid(),
  aipCode: z.string().nullable(),
  reinsuranceYear: z.number().nullable(),
  aipInsuranceAgentKey: z.string().nullable(),
  agentSsnEncrypted: z.string().nullable(),
  userId: z.string().nullable(),
  lastName: z.string().nullable(),
  firstName: z.string().nullable(),
  middleName: z.string().nullable(),
  suffix: z.string().nullable(),
  title: z.string().nullable(),
  email: z.string().nullable(),
  phone: z.string().nullable(),
  inactiveDate: zCalendarDate().nullable(),
  additionalLanguageCodeList: z.string().nullable(),
  listedFlag: z.string().nullable(),
  nonDisclosureSignatureDate: zCalendarDate().nullable(),
  annualReviewDate: zCalendarDate().nullable(),
  coiQuestionnaireCompletionDate: zCalendarDate().nullable(),
  coiQuestionnaireAmendedDate: zCalendarDate().nullable(),
  coiResponseCarryoverFlag: z.string().nullable(),
  coiQuestion1ResponseFlag: z.string().nullable(),
  coiQuestion2ResponseFlag: z.string().nullable(),
  coiQuestion3ResponseFlag: z.string().nullable(),
  coiQuestion4ResponseFlag: z.string().nullable(),
  coiQuestion5ResponseFlag: z.string().nullable(),
  coiQuestion6ResponseFlag: z.string().nullable(),
  coiQuestion7ResponseFlag: z.string().nullable(),
  coiQuestion8ResponseFlag: z.string().nullable(),
  coiQuestion9ResponseFlag: z.string().nullable(),
  coiQuestion10ResponseFlag: z.string().nullable(),
  coiQuestion11ResponseFlag: z.string().nullable(),
  raceQuestionTypeCode: z.string().nullable(),
  ethnicityQuestionTypeCode: z.string().nullable(),
  genderQuestionTypeCode: z.string().nullable(),
  reinsuranceTypeCode: z.string().nullable(),
  createdBy: z.string().nullable(),
  updatedBy: z.string().nullable(),
  importSourceId: z.string().nullable(),
  createdAt: zTimestamp().nullable(),
  updatedAt: zTimestamp().nullable(),
});

export const dbAgentInsertSchema = dbAgentSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .partial();

export const dbAgentUpdateSchema = dbAgentSchema
  .omit({
    id: true,
    createdAt: true,
    updatedAt: true,
  })
  .partial();

expectTypeOf<DbAgent>().toMatchTypeOf<z.infer<typeof dbAgentSchema>>();
expectTypeOf<DbAgentInsert>().toMatchTypeOf<
  z.infer<typeof dbAgentInsertSchema>
>();
expectTypeOf<DbAgentUpdate>().toMatchTypeOf<
  z.infer<typeof dbAgentUpdateSchema>
>();
