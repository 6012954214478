import {
  InsureIQCoverageStatus,
  InsureIQLgmCommodityNames,
  InsureIQLgmCommodityTypeNames,
  InsureIQPolicyTypes,
  RmaAipCodes,
  RmaLgmCommodityTypeCodes,
} from '@harvestiq/constants';
import {
  DbColumnDecimalNullable,
  DbTimestampGenerated,
  DbTimestampNullable,
} from '@harvestiq/utils';
import { zDate, zDecimalNullable, zDecimalRequired } from '@harvestiq/zod';
import { expectTypeOf } from 'expect-type';
import { GeneratedAlways, Insertable, Selectable, Updateable } from 'kysely';
import { z } from 'zod';
import {
  LgmCoverageDetails,
  lgmCoverageDetailsSchema,
} from './coverage-details';
import {
  LgmIndemnityDetails,
  lgmIndemnityDetailsSchema,
} from './lgm-indemnity-details';
import { dbSharedEndorsementSchema } from './shared';

/**
 * Represents an eventual LGM Endorsement table in the database.
 */
export type LgmEndorsementsTable = {
  // Common fields
  id: GeneratedAlways<string>;
  agentSignedAt: DbTimestampNullable;
  signingAgentId: string | null;
  createdBy: string | null;
  customerSignedAt: DbTimestampNullable;
  externalStatus: string | null;
  isExternallyManaged: boolean;
  reinsuranceYear: number;
  policyId: string | null;
  salesEffectiveDate: string;
  status: InsureIQCoverageStatus;
  submittedToAipAt: DbTimestampNullable;
  entityHistoryId: string | null;
  aipCode: RmaAipCodes | null;
  importSourceId: string | null;
  canceledByImportSourceId: string | null;
  totalPremium: DbColumnDecimalNullable;
  subsidyTotal: DbColumnDecimalNullable;
  producerPremiumTotal: DbColumnDecimalNullable;
  watchedAt: DbTimestampNullable;
  createdAt: DbTimestampGenerated;
  updatedAt: DbTimestampGenerated;
  deletedAt: DbTimestampNullable;
  statusUpdatedAt: DbTimestampNullable;
  premiumUpdatedAt: DbTimestampNullable;
  indemnityUpdatedAt: DbTimestampNullable;

  // LGM fields
  commodity: InsureIQLgmCommodityNames;
  type: InsureIQPolicyTypes;
  commodityType: InsureIQLgmCommodityTypeNames;
  commodityTypeCode: RmaLgmCommodityTypeCodes | null;
  lgmIndemnityDetails: LgmIndemnityDetails | null;
  details: LgmCoverageDetails;
  aipLgmPremiumKey: string | null;
  liveCattleTargetCwtPerHead: DbColumnDecimalNullable;
  feederCattleTargetCwtPerHead: DbColumnDecimalNullable;
  cornTargetBuPerHead: DbColumnDecimalNullable;
  deductible: number | null;
  targetMarketingsTotal: number | null;
  expectedGrossMarginTotal: DbColumnDecimalNullable;
  grossMarginGuaranteeTotal: DbColumnDecimalNullable;
  lgmGrossMarginChangeTotal: DbColumnDecimalNullable;
  lgmActualGrossMarginTotal: DbColumnDecimalNullable;
  lgmActualIndemnity: DbColumnDecimalNullable;
  lgmNetActualIndemnity: DbColumnDecimalNullable;
  lgmProjectedGrossMarginTotal: DbColumnDecimalNullable;
  lgmProjectedIndemnity: DbColumnDecimalNullable;
  lgmNetProjectedIndemnity: DbColumnDecimalNullable;

  // LRP fields
  // TODO: Remove once we have two separate tables
  liabilityAmountTotal: never;
  liabilityAmountPerCwt: never;
  liabilityAmountPerHead: never;
  livestockRate: never;
  expectedEndingValueTotal: never;
  expectedEndingValuePerCwt: never;
  expectedEndingValuePerHead: never;
  actualEndingValueTotal: never;
  actualEndingValuePerCwt: never;
  actualEndingValuePerHead: never;
  actualIndemnityTotal: never;
  actualIndemnityPerCwt: never;
  actualIndemnityPerHead: never;
  netActualIndemnityTotal: never;
  netActualIndemnityPerCwt: never;
  netActualIndemnityPerHead: never;
  projectedCashEndingValueTotal: never;
  projectedCashEndingValuePerCwt: never;
  projectedCashEndingValuePerHead: never;
  projectedCashIndemnityTotal: never;
  projectedCashIndemnityPerCwt: never;
  projectedCashIndemnityPerHead: never;
  netProjectedCashIndemnityTotal: never;
  netProjectedCashIndemnityPerCwt: never;
  netProjectedCashIndemnityPerHead: never;
  projectedFuturesEndingValueTotal: never;
  projectedFuturesEndingValuePerCwt: never;
  projectedFuturesEndingValuePerHead: never;
  projectedFuturesIndemnityTotal: never;
  projectedFuturesIndemnityPerCwt: never;
  projectedFuturesIndemnityPerHead: never;
  netProjectedFuturesIndemnityTotal: never;
  netProjectedFuturesIndemnityPerCwt: never;
  netProjectedFuturesIndemnityPerHead: never;
  aipLrpPremiumKey: never;
  coverageLevel: never;
  coveragePriceTotal: never;
  coveragePricePerCwt: never;
  coveragePricePerHead: never;
  endorsementLength: never;
  headCount: never;
  producerPremiumPerCwt: never;
  producerPremiumPerHead: never;
  subsidyPerCwt: never;
  subsidyPerHead: never;
  subsidyRate: never;
  targetWeightQuantityCwt: never;
  totalPremiumPerCwt: never;
  totalPremiumPerHead: never;
};

export type DbLgmEndorsement = Selectable<LgmEndorsementsTable>;
export type DbLgmEndorsementInsert = Insertable<LgmEndorsementsTable>;
export type DbLgmEndorsementUpdate = Updateable<LgmEndorsementsTable>;

export const dbLgmEndorsementSchema = dbSharedEndorsementSchema.extend({
  commodity: z.nativeEnum(InsureIQLgmCommodityNames),
  type: z.literal(InsureIQPolicyTypes.LGM),
  commodityType: z.nativeEnum(InsureIQLgmCommodityTypeNames),
  commodityTypeCode: z.nativeEnum(RmaLgmCommodityTypeCodes).nullable(),
  signingAgentId: z.string().uuid().nullable(),
  details: lgmCoverageDetailsSchema,
  lgmIndemnityDetails: lgmIndemnityDetailsSchema.nullable(),
  aipLgmPremiumKey: z.string().nullable(),
  liveCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  feederCattleTargetCwtPerHead: zDecimalRequired().nullable(),
  cornTargetBuPerHead: zDecimalRequired().nullable(),
  deductible: z.number().nullable(),
  targetMarketingsTotal: z.number().nullable(),
  expectedGrossMarginTotal: zDecimalNullable(),
  grossMarginGuaranteeTotal: zDecimalNullable(),
  lgmGrossMarginChangeTotal: zDecimalNullable(),
  lgmActualGrossMarginTotal: zDecimalNullable(),
  lgmActualIndemnity: zDecimalNullable(),
  lgmNetActualIndemnity: zDecimalNullable(),
  lgmProjectedGrossMarginTotal: zDecimalNullable(),
  lgmProjectedIndemnity: zDecimalNullable(),
  lgmNetProjectedIndemnity: zDecimalNullable(),
});

expectTypeOf<keyof DbLgmEndorsement>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementSchema>
>();

export const dbLgmEndorsementInsertSchema = dbLgmEndorsementSchema
  .omit({
    id: true,
    createdAt: true,
  })
  .extend({
    agentSignedAt: zDate().nullable(),
    // Insert as string or date
    customerSignedAt: zDate().nullable(),
    submittedToAipAt: zDate().nullable(),
    watchedAt: zDate().nullable(),
    createdAt: zDate(),
    updatedAt: zDate(),
    deletedAt: zDate().nullable(),
  });

expectTypeOf<keyof DbLgmEndorsementInsert>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementInsertSchema>
>();

export const dbLgmEndorsementUpdateSchema =
  dbLgmEndorsementInsertSchema.partial();

expectTypeOf<keyof DbLgmEndorsementUpdate>().toEqualTypeOf<
  keyof z.output<typeof dbLgmEndorsementUpdateSchema>
>();
