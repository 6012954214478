import { z } from 'zod';
import { dbPolicySchema, dbPolicyUpdateSchema } from './db-policy';

export const updatePolicyRequestSchema = dbPolicyUpdateSchema;

export type UpdatePolicyRequest = z.infer<typeof updatePolicyRequestSchema>;

export const updatePolicyResponseSchema = z.object({
  policy: dbPolicySchema,
});
export type UpdatePolicyResponse = z.infer<typeof updatePolicyResponseSchema>;
